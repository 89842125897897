const environment = "prod";
//const environment = "development";
//const environment = "test";

const env = require("./environment.json")[environment];

const config = {
  API_BASE_URL: env.API_BASE_URL,
  WS_BASE_URL: env.WS_BASE_URL,
};

export default config;
