import {
  React,
  useState,
  useEffect,
  config,
  Swal,
  handleLogout,
} from "../utils/commonImports";
import { getCookie, expireCookie } from "../utils/cookieUtils";
import { Card, CardContent, Typography, Divider, Button } from "@mui/material";
import { formatCurrency } from "../utils/formatterUtils";

const Margin = ({ toggleLoading, updateAvailableMargin }) => {
  const [data, setData] = useState(null);

  async function handleRefresh() {
    try {
      this.getMargins();
    } catch (error) {
      console.error("handleRefresh error:", error);
    }
  }

  useEffect(() => {
    const getMargins = async () => {
      try {
        //read cookie from browser access_token
        const access_token = getCookie("access_token");
        //toggleLoading(true);
        const response = await fetch(
          config.API_BASE_URL +
            "dashboard/getMargins?access_token=" +
            access_token,
          {
            method: "GET",
          }
        );
        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData.data);
          updateAvailableMargin(jsonData.data.net);
        } else {
          const errorData = await response.json();
          console.log(errorData);
          Swal.fire("Error", "Error fetching Margin data.", "error");
          handleLogout();
        }
        //toggleLoading(false);
      } catch (error) {
        //toggleLoading(false);
        console.error("Error fetching Margin data:", error);
        handleLogout();
      }
    };

    getMargins();
    // Use setInterval to call getMargins every 2 seconds
    const intervalId = setInterval(() => {
      getMargins();
    }, 200000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [toggleLoading]);

  if (!data) {
    return <div>Loading Margin...</div>;
  }

  return (
    <div
      className="dashboard"
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        margin: "16px",
        padding: "16px",
      }}
    >
      {/* <Typography className="typography-flex">
        Enabled: {data.enabled.toString()}
      </Typography> */}

      <Typography variant="h5" style={{ marginBottom: "16px" }}>
        FUNDS
      </Typography>
      <Typography className="typography-flex">
        <span className="title-left">NET BALANCE</span>
        <span className="value-right">{formatCurrency(data.net)}</span>
      </Typography>
      <Typography className="typography-flex">
        <span className="title-left">ADHOC MARGIN</span>
        <span className="value-right">
          {formatCurrency(data.available.adhoc_margin)}
        </span>
      </Typography>
      <Typography className="typography-flex">
        <span className="title-left">CASH</span>
        <span className="value-right">
          {formatCurrency(data.available.cash)}
        </span>
      </Typography>
      <Typography className="typography-flex">
        <span className="title-left">OPENING BALANCE</span>
        <span className="value-right">
          {formatCurrency(data.available.opening_balance)}
        </span>
      </Typography>
      <Typography className="typography-flex">
        <span className="title-left">LIVE BALANCE</span>
        <span className="value-right">
          {formatCurrency(data.available.live_balance)}
        </span>
      </Typography>
      <Typography className="typography-flex">
        <span className="title-left">COLLATERAL</span>
        <span className="value-right">
          {formatCurrency(data.available.collateral)}
        </span>
      </Typography>
    </div>
  );
};

export default Margin;
