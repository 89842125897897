// SwipeableDrawerComponent.jsx
import React from "react";
import {
  SwipeableDrawer,
  Box,
  Button,
} from "@mui/material";
import { handleLogout } from "../utils/commonImports";

async function redirectToKite() {
  try {
    window.open("https://kite.zerodha.com/", "_blank");
  } catch (error) {
    console.error("redirectToKite error:", error);
  }
}

const NavigationDrawer = ({
  anchor,
  open,
  onClose,
  onOpen,
}) => {
  const redirectToLogs = () => {
    try {
      window.open("/logs", "_blank");
    } catch (error) {
      console.error("redirectToLogs error:", error);
    }
  };

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 3,
        }}
      >
        <Button
          height={50}
          style={{ padding: "10px" }}
          onClick={redirectToKite}
        >
          KITE DASHBOARD
        </Button>
        <Button
          height={50}
          style={{ padding: "10px" }}
          onClick={redirectToLogs}
        >
          Logs
        </Button>
        <Button height={50} style={{ padding: "10px" }} onClick={handleLogout}>
          LOGOUT
        </Button>
      </Box>
    </SwipeableDrawer>
  );
};

export default NavigationDrawer;
