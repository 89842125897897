import React, { useEffect, useState, useRef } from "react";
import config from "../config/config";
import Swal from "sweetalert2";
import { Button, Paper, Typography } from "@mui/material";

const LogViewer = () => {
  const [logs, setLogs] = useState([]);
  const logsContainerRef = useRef(null);

  document.title = "Logs";

  useEffect(() => {
    const interval = setInterval(() => {
      // Code to be executed every 5 seconds
      fetch(config.API_BASE_URL + "logs")
        .then((response) => response.json())
        .then((data) => {
          // Split the log file content by newline to get individual logs
          //const logLines = data.split("\n");
          setLogs(data);
        })
        .catch((error) => {
          Swal.fire("Error", "Error fetching log file.", error);
        });
    }, 5000);

    // Cleanup function to clear the interval when component unmounts
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (logsContainerRef.current) {
      logsContainerRef.current.scrollTop =
        logsContainerRef.current.scrollHeight;
    }
  }, [logs]);

  return (
    <Paper elevation={3} style={{ padding: "16px" }}>
      <Typography variant="h5">Log Viewer</Typography>
      <div style={{ height: '100%', overflow: "auto" }} ref={logsContainerRef}>
        {logs.length === 0 ? (
          <p>Loading...</p>
        ) : (
          logs.slice(-100).map((log, index) => <div key={index}>{log}</div>)
        )}
      </div>
    </Paper>
  );
};

export default LogViewer;
