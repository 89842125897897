import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ThemeWrapper from "./components/ThemeWrapper"; // Import the ThemeWrapper component
import Login from "./components/login";
import Dashboard from "./components/dashboard";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Margin from "./components/margins";
import Instrument from "./components/instruments";
import Profile from "./components/profile";
import Positions from "./components/positions";
import LogViewer from "./components/logviewer";

function App() {
  return (
    <ThemeWrapper>
      {/* Wrap your app with the ThemeWrapper */}
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/instruments" element={<Instrument />} />
          <Route path="/positions" element={<Positions />} />
          <Route path="/logs" element={<LogViewer />} />
        </Routes>
      </Router>
    </ThemeWrapper>
  );
}

export default App;
