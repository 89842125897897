import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Box, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationDrawer from "./navigationDrawer";
import { formatCurrency } from "../utils/formatterUtils";
import DigitalClock from "./digitalClock"; //

const SideBar = (availableMargins) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const drawerTitleContent = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: 2,
        justifyContent: "flex-end",
      }}
    >
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        {availableMargins &&
          `${formatCurrency(availableMargins.availableMargins)}`}
      </Typography>
      <DigitalClock />
    </Box>
  );

  return (
    <React.Fragment>
      <AppBar position="static">
        <Toolbar
          style={{ backgroundColor: "#f0f0f0", color: "#000000" }}
          elevation={0}
          variant="dense"
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          {drawerTitleContent}
        </Toolbar>
      </AppBar>
      <NavigationDrawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      />
    </React.Fragment>
  );
};

export default SideBar;
