import {
  React,
  useState,
  useEffect,
  config,
  Swal,
  handleLogout,
} from "../utils/commonImports";

import DigitalClock from "./digitalClock";
import RootLinearProgress from "./rootLinearProgress";
import { formatCurrency } from "../utils/formatterUtils";
import { Button, Typography, Grid, Paper, Hidden } from "@mui/material";
import { styled } from "@mui/system";

import "../style/header.css";

const TopBar = styled(Paper)(({ theme }) => ({
  backgroundColor: "#f0f0f0",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(1),
  display: "flex",
  justifyContent: "flex-end",
}));

async function redirectToKite() {
  try {
    window.open("https://kite.zerodha.com/", "_blank");
  } catch (error) {
    console.error("redirectToKite error:", error);
  }
}

const Header = ({ availableMargins }) => {
  const [loading, setLoading] = useState(false);
  //const [availableMargins, setAvailableMargins] = useState([]);

  const redirectToLogs = () => {
    try {
      window.open("/logs", "_blank");
    } catch (error) {
      console.error("redirectToLogs error:", error);
    }
  };

  return (
    <Grid className="header">
      <Grid item xs={12}>
        <TopBar elevation={3}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {formatCurrency(availableMargins)}
          </Typography>
          <DigitalClock />
          <Hidden smDown>
            <Button
              variant="outlined"
              style={{ marginRight: "10px" }}
              onClick={redirectToKite}
            >
              KITE DASHBOARD
            </Button>
            <Button
              variant="outlined"
              style={{ marginRight: "10px" }}
              onClick={redirectToLogs}
            >
              Logs
            </Button>
            <Button variant="outlined" onClick={handleLogout}>
              LOGOUT
            </Button>
          </Hidden>
        </TopBar>
        <RootLinearProgress loading={loading} />
      </Grid>
    </Grid>
  );
};

export default Header;
