import React from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Paper,
  Divider,
} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Swal from "sweetalert2";

class OptionsCalculator extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tradingsymbol: props.tradingsymbol,
      strike: props.strike,
      expiry: props.expiry,
      optionType: props.optionType,
      selectedlotSizeButton: props.lotSize,
      selectedSellPriceIndex: null,
      selectedStopLossIndex: null,
      orderBuyPrice: 0,
      orderSellPrice: 0,
      orderLotSize: 0,
      instrumentType: props.instrumentType,
      sellPrices: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        22, 25, 30, 35, 40, 45, 50,
      ],
      stopLosses: [
        0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 7.5, 10, 12.5, 15, 20, 25, 30, 35, 40, 45,
        50,
      ],
      lotSizes: Array.from(Array(12), (_, i) => (i + 1) * 50),
      lastPrice: props.lastPrice,
      lotSize: props.lotSize,
      turnover: 0,
      brokerage: 40,
      sttTotal: 0,
      etc: 0,
      stax: 0,
      sebiCharges: 0,
      stampCharges: 0,
      totalTax: 0,
      breakeven: 0,
      netProfit: 0,
      usedMargin: 0,
      expectedReturn: 0,
      orderturnover: 0,
      orderbrokerage: 40,
      ordersttTotal: 0,
      orderetc: 0,
      orderstax: 0,
      ordersebiCharges: 0,
      orderstampCharges: 0,
      ordertotalTax: 0,
      orderbreakeven: 0,
      ordernetProfit: 0,
      orderusedMargin: 0,
      orderexpectedReturn: 0,
      availableMargins: props.availableMargins,
    };
  }

  componentDidMount() {
    //console.log("componentDidMount");
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log("componentDidUpdate");
    if (
      prevProps.tradingsymbol !== this.props.tradingsymbol ||
      prevProps.lastPrice !== this.props.lastPrice ||
      prevProps.strike !== this.props.strike ||
      prevProps.expiry !== this.props.expiry ||
      prevProps.optionType !== this.props.optionType ||
      prevProps.lotSize !== this.props.lotSize ||
      prevProps.instrumentType !== this.props.instrumentType
    ) {
      this.setState({
        tradingsymbol: this.props.tradingsymbol,
        lastPrice: this.props.lastPrice,
        strike: this.props.strike,
        expiry: this.props.expiry,
        optionType: this.props.optionType,
        lotSize: this.props.lotSize,
        instrumentType: this.props.instrumentType,
        availableMargins: this.props.availableMargins,
      });
    }

    // Check if the relevant state has actually changed to avoid loops

    if (
      prevState.lastPrice !== this.state.lastPrice ||
      prevState.lotSize !== this.state.lotSize ||
      prevState.selectedSellPriceIndex !== this.state.selectedSellPriceIndex
    ) {
      if (
        this.state.lastPrice !== null &&
        this.state.lotSize !== null &&
        this.state.selectedSellPriceIndex !== null
      ) {
        this.calOptions(
          this.state.lastPrice,
          this.calculateSellPriceByPercentage(this.state.lastPrice)[
            this.state.selectedSellPriceIndex
          ],
          this.state.lotSize
        );
      }
    }
  }

  handleReset = () => {
    this.setState({
      tradingsymbol: "",
      strike: "",
      expiry: "",
      optionType: "",
      selectedlotSizeButton: "",
      selectedSellPriceIndex: null,
      selectedStopLossIndex: null,
      orderBuyPrice: 0,
      orderSellPrice: 0,
      orderLotSize: 0,
      instrumentType: "",
      sellPrices: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],
      stopLosses: [
        0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 7.5, 10, 12.5, 15, 20, 25, 30, 35, 40, 45,
        50,
      ],
      lotSizes: Array.from(Array(12), (_, i) => (i + 1) * 50),
      lastPrice: 0,
      lotSize: 0,
      turnover: 0,
      brokerage: 40,
      sttTotal: 0,
      etc: 0,
      stax: 0,
      sebiCharges: 0,
      stampCharges: 0,
      totalTax: 0,
      breakeven: 0,
      netProfit: 0,
      usedMargin: 0,
      expectedReturn: 0,
      orderturnover: 0,
      orderbrokerage: 40,
      ordersttTotal: 0,
      orderetc: 0,
      orderstax: 0,
      ordersebiCharges: 0,
      orderstampCharges: 0,
      ordertotalTax: 0,
      orderbreakeven: 0,
      ordernetProfit: 0,
      orderusedMargin: 0,
      orderexpectedReturn: 0,
    });
  };

  handleLotSizeClick = (lotSize) => {
    this.setState({ lotSize });
    this.setState({ selectedlotSizeButton: lotSize });
  };

  handleSellPriceClick = (index) => {
    /* const sellprice = this.calculateSellPriceByPercentage(this.state.lastPrice)[
      index
    ]; */
    //console.log("sellprice", sellprice);
    this.setState({ selectedSellPriceIndex: index });
  };

  handleStopLossPriceClick = (index) => {
    /* const stoplossprice = this.calculateStopLossByPercentage(this.state.orderBuyPrice)[
      index
    ]; */
    this.setState({ selectedStopLossIndex: index });
  };

  handleSetOrderClick = (tradingsymbol, lastPrice, lotSize, sellPrice) => {
    if (tradingsymbol === "") {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a tradingsymbol!",
      });
      return;
    }

    if (lastPrice === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a last price!",
      });
      return;
    }

    if (lotSize === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a lot size!",
      });
      return;
    }

    if (sellPrice === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please select a sell price!",
      });
      return;
    }

    this.setState({
      orderBuyPrice: lastPrice,
      orderSellPrice: sellPrice,
      orderLotSize: lotSize,
    });
  };

  calculateSellPriceByPercentage = (lastprice) => {
    const { sellPrices } = this.state;
    const sellPricesArray = sellPrices.map((percentage) => {
      return (lastprice * (1 + percentage / 100)).toFixed(2);
    });

    return sellPricesArray;
  };

  calculateStopLossByPercentage = (buyPrice) => {
    const { stopLosses } = this.state;
    const stopLossesArray = stopLosses.map((percentage) => {
      const lossAmount = buyPrice * (percentage / 100);
      const stopLossPrice = buyPrice - lossAmount;
      return stopLossPrice.toFixed(2);
    });

    return stopLossesArray;
  };

  calculateCharges = (bp, sp, qty) => {
    if (
      bp === 0 ||
      sp === 0 ||
      qty === 0 ||
      isNaN(qty) ||
      (isNaN(bp) && isNaN(sp))
    ) {
      return null;
    }

    let brokerage = bp === 0 || sp === 0 ? 20 : 40;

    const turnover = parseFloat(((bp + sp) * qty).toFixed(2));
    const sttTotal = Math.round((sp * qty * 0.000625).toFixed(2));
    let etc = parseFloat((0.0005 * turnover).toFixed(2));
    const nse_ipft = parseFloat(parseFloat(0.000005 * turnover).toFixed(2));
    etc = parseFloat(parseFloat(etc + nse_ipft).toFixed(2));
    let sebiCharges = parseFloat((turnover * 0.000001).toFixed(2));
    sebiCharges = parseFloat((sebiCharges + sebiCharges * 0.18).toFixed(2));
    const stax = parseFloat(
      (0.18 * (brokerage + etc + sebiCharges)).toFixed(2)
    );
    const stampCharges = Math.round((bp * qty * 0.00003).toFixed(2));
    const totalTax = parseFloat(
      (brokerage + sttTotal + etc + stax + sebiCharges + stampCharges).toFixed(
        2
      )
    );
    const breakeven = parseFloat((totalTax / qty).toFixed(2));
    const netProfit = parseFloat(((sp - bp) * qty - totalTax).toFixed(2));

    return {
      turnover,
      brokerage,
      sttTotal,
      etc,
      stax,
      sebiCharges,
      stampCharges,
      totalTax,
      breakeven,
      netProfit,
    };
  };

  sellForeCast = (lastPrice, sellPrice, lotSize) => {
    let bp = parseFloat(lastPrice);
    let sp = parseFloat(sellPrice);
    let qty = parseFloat(lotSize);

    const charges = this.calculateCharges(bp, sp, qty);

    if (charges) {
      return charges.netProfit;
    }
  };

  calOptions = (lastPrice, sellPrice, lotSize) => {
    let bp = parseFloat(lastPrice);
    let sp = parseFloat(sellPrice);
    let qty = parseFloat(lotSize);

    const charges = this.calculateCharges(bp, sp, qty);

    if (charges) {
      this.setState({
        turnover: charges.turnover,
        brokerage: charges.brokerage,
        sttTotal: charges.sttTotal,
        etc: charges.etc,
        stax: charges.stax,
        sebiCharges: charges.sebiCharges,
        stampCharges: charges.stampCharges,
        totalTax: charges.totalTax,
        breakeven: charges.breakeven,
        netProfit: charges.netProfit,
      });
    }
  };

  render() {
    const { sellPrices, lotSizes, stopLosses } = this.state;

    return (
      <div>
        <Grid container spacing={2}>
          <Grid item sm={12} xs={12}>
            <Grid
              container
              spacing={3}
              style={{
                padding: "12px",
              }}
            >
              <Grid item xs={12}>
                <Typography variant="h6">LOT SIZE</Typography>
              </Grid>
              {lotSizes.map((lotSize) => (
                <Grid item xs={2.5} sm={1} key={lotSize}>
                  <Button
                    variant="outlined"
                    style={{
                      spacing: 2,
                      backgroundColor:
                        this.state.selectedlotSizeButton &&
                        this.state.selectedlotSizeButton === lotSize &&
                        this.state.lastPrice * lotSize <
                          this.state.availableMargins
                          ? "#bafac3" // Set the desired style for the selected button
                          : this.state.lastPrice * lotSize >=
                            this.state.availableMargins
                          ? "#e7e7e7" // Set the style for the disabled button
                          : "white", // Set the default style for other buttons
                    }}
                    onClick={() => this.handleLotSizeClick(lotSize)}
                    disabled={
                      this.state.lastPrice * lotSize >=
                      this.state.availableMargins
                    }
                  >
                    {lotSize}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item sm={12} xs={12}>
            <Grid
              container
              spacing={2}
              id="options-equity-calc"
              style={{
                marginBottom: "16px",
                padding: "12px",
              }}
            >
              <Grid item xs={12}>
                <Typography variant="h6">SELL PRICE</Typography>
              </Grid>
              {sellPrices.map((percentage, index) => {
                const sellPrice = this.calculateSellPriceByPercentage(
                  this.state.lastPrice
                )[index];
                var netProfit = this.sellForeCast(
                  this.state.lastPrice,
                  sellPrice,
                  this.state.lotSize
                );
                if (netProfit === undefined) {
                  netProfit = 0;
                }

                const isPositiveProfit = netProfit >= 0;

                return (
                  <Grid item xs={4} sm={2} key={index}>
                    <Button
                      variant="outlined"
                      disabled={netProfit < 1}
                      style={{
                        backgroundColor:
                          this.state.selectedSellPriceIndex &&
                          this.state.selectedSellPriceIndex === index
                            ? netProfit > 0
                              ? "#ecfaee"
                              : "#faefea" // Set the desired style for the selected button
                            : "white", // Set the default style for other buttons
                        color:
                          netProfit > 0
                            ? "#6add79"
                            : netProfit < 1
                            ? "#eb8861"
                            : "#e7e7e7",
                        borderColor:
                          netProfit > 0
                            ? "#6add79"
                            : netProfit < 1
                            ? "#eb8861"
                            : "#e7e7e7",
                        width: "100%",
                      }}
                      onClick={() => this.handleSellPriceClick(index)}
                    >
                      {percentage}% -
                      {
                        this.calculateSellPriceByPercentage(
                          this.state.lastPrice
                        )[index]
                      }
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          spacing={2}
          style={{
            marginBottom: "12px",
            padding: "12px",
          }}
        >
          <Grid item sm={6} xs={12}>
            <Typography variant="h5">{this.state.tradingsymbol}</Typography>
          </Grid>
          <Grid item sm={2} xs={6}>
            <Typography variant="h5" className="typography-flex">
              <span className="title-left">BUY</span>
              <span className="value-right">{this.state.lastPrice}</span>
            </Typography>
          </Grid>
          <Grid item sm={2} xs={6}>
            <Typography variant="h5" className="typography-flex">
              <span className="title-left">SELL</span>
              <span className="value-right">
                {this.state.lastPrice && this.state.selectedSellPriceIndex
                  ? this.calculateSellPriceByPercentage(this.state.lastPrice)[
                      this.state.selectedSellPriceIndex
                    ]
                  : 0}
              </span>
            </Typography>
          </Grid>
          <Grid item sm={2} xs={6}>
            <Typography variant="h5" className="typography-flex">
              <span className="title-left">LOT</span>
              <span className="value-right">{this.state.lotSize}</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              style={{
                marginBottom: "16px",
                padding: "12px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">TURNOVER</span>
                  <span className="value-right">{this.state.turnover}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">BROKERAGE</span>
                  <span className="value-right">{this.state.brokerage}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">STT</span>
                  <span className="value-right">{this.state.sttTotal}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">ETC</span>
                  <span className="value-right">{this.state.etc}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">GST</span>
                  <span className="value-right">{this.state.stax}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">SEBI</span>
                  <span className="value-right">{this.state.sebiCharges}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">STAMP</span>
                  <span className="value-right">{this.state.stampCharges}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">TOTAL TAX</span>
                  <span className="value-right">{this.state.totalTax}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">BREAKEVEN</span>
                  <span className="value-right">{this.state.breakeven}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography
                  className="typography-flex"
                  style={{ color: this.state.netProfit < 0 ? "red" : "green" }}
                >
                  <span className="title-left">USED MARGIN</span>
                  <span className="value-right">
                    {" "}
                    {parseFloat(
                      this.state.lastPrice * this.state.lotSize
                    ).toFixed(2)}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography
                  className="typography-flex"
                  style={{ color: this.state.netProfit < 0 ? "red" : "green" }}
                >
                  <span className="title-left">NET P/L</span>
                  <span className="value-right">{this.state.netProfit}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography
                  className="typography-flex"
                  style={{ color: this.state.netProfit < 0 ? "red" : "green" }}
                >
                  <span className="title-left">EXPECTED RETURN</span>
                  <span className="value-right">
                    {parseFloat(
                      this.state.lastPrice * this.state.lotSize +
                        this.state.netProfit
                    ).toFixed(2)}
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Grid
              container
              spacing={2}
              id="options-equity-order"
              style={{
                marginBottom: "16px",
                padding: "12px",
              }}
            >
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    this.handleSetOrderClick(
                      this.state.tradingsymbol,
                      this.state.lastPrice,
                      this.state.lotSize,
                      this.state.selectedSellPriceIndex
                        ? this.calculateSellPriceByPercentage(
                            this.state.lastPrice
                          )[this.state.selectedSellPriceIndex]
                        : 0
                    )
                  }
                >
                  PREPARE ORDER
                </Button>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled
                  onClick={() =>
                    this.handleSetOrderClick(
                      this.state.tradingsymbol,
                      this.state.lastPrice,
                      this.state.lotSize,
                      this.state.selectedSellPriceIndex
                        ? this.calculateSellPriceByPercentage(
                            this.state.lastPrice
                          )[this.state.selectedSellPriceIndex]
                        : 0
                    )
                  }
                >
                  SMART ORDER
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">ORDER DETAILS</Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography className="typography-flex">
                  <TextField
                    id="outlined-basic"
                    label="BUY PRICE"
                    variant="outlined"
                    className="value-right"
                    fullWidth
                    value={this.state.orderBuyPrice <= this.state.lastPrice ? this.state.orderBuyPrice : this.state.lastPrice}
                    onChange={this.handleBuyPriceChange}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography className="typography-flex">
                  <TextField
                    id="outlined-basic"
                    label="SELL PRICE"
                    variant="outlined"
                    className="value-right"
                    fullWidth
                    value={this.state.orderSellPrice}
                    onChange={this.handleSellPriceChange}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography className="typography-flex">
                  <TextField
                    id="outlined-basic"
                    label="LOT SIZE"
                    variant="outlined"
                    className="value-right"
                    disabled
                    fullWidth
                    value={this.state.orderLotSize}
                  />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              id="options-equity-order-calculator"
              style={{
                marginBottom: "16px",
                padding: "12px",
              }}
            >
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">TURNOVER</span>
                  <span className="value-right">
                    {this.state.orderturnover}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">BROKERAGE</span>
                  <span className="value-right">
                    {this.state.orderbrokerage}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">STT</span>
                  <span className="value-right">
                    {this.state.ordersttTotal}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">ETC</span>
                  <span className="value-right">{this.state.orderetc}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">GST</span>
                  <span className="value-right">{this.state.orderstax}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">SEBI</span>
                  <span className="value-right">
                    {this.state.ordersebiCharges}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">STAMP</span>
                  <span className="value-right">
                    {this.state.orderstampCharges}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">TOTAL TAX</span>
                  <span className="value-right">
                    {this.state.ordertotalTax}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">BREAKEVEN</span>
                  <span className="value-right">
                    {this.state.orderbreakeven}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography
                  className="typography-flex"
                  style={{
                    color: this.state.ordernetProfit < 0 ? "red" : "green",
                  }}
                >
                  <span className="title-left">USED MARGIN</span>
                  <span className="value-right">
                    {" "}
                    {parseFloat(
                      this.state.orderBuyPrice * this.state.orderLotSize
                    ).toFixed(2)}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography
                  className="typography-flex"
                  style={{
                    color: this.state.ordernetProfit < 0 ? "red" : "green",
                  }}
                >
                  <span className="title-left">NET P/L</span>
                  <span className="value-right">
                    {this.state.ordernetProfit}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography
                  className="typography-flex"
                  style={{
                    color: this.state.ordernetProfit < 0 ? "red" : "green",
                  }}
                >
                  <span className="title-left">EXPECTED RETURN</span>
                  <span className="value-right">
                    {parseFloat(
                      this.state.orderBuyPrice * this.state.orderLotSize +
                        this.state.ordernetProfit
                    ).toFixed(2)}
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              id="options-equity-order"
              style={{
                marginBottom: "16px",
                padding: "12px",
              }}
            >
              <Grid item xs={12}>
                <Typography variant="h6">STOP LOSS</Typography>
              </Grid>
              {stopLosses.map((percentage, index) => {
                const stopLossPrice = this.calculateStopLossByPercentage(
                  this.state.orderBuyPrice
                )[index];

                return (
                  <Grid item xs={4} sm={2} key={index}>
                    <Button
                      variant="outlined"
                      disabled={stopLossPrice < 1}
                      style={{
                        backgroundColor:
                          this.state.selectedStopLossIndex &&
                          this.state.selectedStopLossIndex === index
                            ? "#faefea" // Set the desired style for the selected button
                            : "white", // Set the default style for other buttons
                        color: "#eb8861",
                        borderColor: "#eb8861",
                        width: "100%",
                      }}
                      onClick={() => this.handleStopLossPriceClick(index)}
                    >
                      {percentage}% -
                      {
                        this.calculateStopLossByPercentage(
                          this.state.orderBuyPrice
                        )[index]
                      }
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              container
              spacing={2}
              id="options-equity-order-calculator"
              style={{
                marginBottom: "16px",
                padding: "12px",
              }}
            >
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">TURNOVER</span>
                  <span className="value-right">
                    {this.state.orderturnover}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">BROKERAGE</span>
                  <span className="value-right">
                    {this.state.orderbrokerage}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">STT</span>
                  <span className="value-right">
                    {this.state.ordersttTotal}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">ETC</span>
                  <span className="value-right">{this.state.orderetc}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">GST</span>
                  <span className="value-right">{this.state.orderstax}</span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">SEBI</span>
                  <span className="value-right">
                    {this.state.ordersebiCharges}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">STAMP</span>
                  <span className="value-right">
                    {this.state.orderstampCharges}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">TOTAL TAX</span>
                  <span className="value-right">
                    {this.state.ordertotalTax}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography className="typography-flex">
                  <span className="title-left">BREAKEVEN</span>
                  <span className="value-right">
                    {this.state.orderbreakeven}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography
                  className="typography-flex"
                  style={{
                    color: this.state.ordernetProfit < 0 ? "red" : "green",
                  }}
                >
                  <span className="title-left">USED MARGIN</span>
                  <span className="value-right">
                    {" "}
                    {parseFloat(
                      this.state.orderBuyPrice * this.state.orderLotSize
                    ).toFixed(2)}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography
                  className="typography-flex"
                  style={{
                    color: this.state.ordernetProfit < 0 ? "red" : "green",
                  }}
                >
                  <span className="title-left">NET P/L</span>
                  <span className="value-right">
                    {this.state.ordernetProfit}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography
                  className="typography-flex"
                  style={{
                    color: this.state.ordernetProfit < 0 ? "red" : "green",
                  }}
                >
                  <span className="title-left">EXPECTED RETURN</span>
                  <span className="value-right">
                    {parseFloat(
                      this.state.orderBuyPrice * this.state.orderLotSize +
                        this.state.ordernetProfit
                    ).toFixed(2)}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Button variant="contained" color="primary">
                  PLACE ORDER
                </Button>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button variant="contained" color="primary">
                  PLACE ORDER + STOPLOSS
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Divider />
      </div>
    );
  }
}

export default OptionsCalculator;
