import React, { useState, useEffect } from "react";
import { styled } from "@mui/system";
import { Paper, Typography, TextField, Button, Grid } from "@mui/material";
import config from "../config/config";
import Swal from "sweetalert2";
import { getCookie } from "../utils/cookieUtils";
import devcraftLogo from "../assets/images/devcraft.png";
import "../style/index.css";

const LoginContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: "#f0f0f0",
  padding: theme.spacing(3),
  borderRadius: theme.spacing(1),
}));

const LoginForm = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  marginTop: theme.spacing(2),
}));

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    //check if cookie exists
    if (document.cookie) {
      const access_token = getCookie("access_token");
      console.log(access_token);
      if (access_token !== undefined && access_token !== null) {
        //window.location.href = "/dashboard";
        return;
      }
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(config.API_BASE_URL + "login", {
        method: "GET",
      });

      if (response.ok) {
        const data = await response.json();
        window.location.href = data.url;
      } else {
        const errorData = await response.json();
        console.log(errorData);
        Swal.fire("Error", "Login failed. Please try again.", "error");
      }
    } catch (error) {
      console.log(error);
      Swal.fire("Error", "An error occurred. Please try again later.", "error");
    }
  };

  return (
    <LoginContainer elevation={3}>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12} md={3}>
          <img src={devcraftLogo} alt="logo" className="logo" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4">Kite Connect</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleLogin}
          >
            Login
          </Button>
        </Grid>
      </Grid>

      {/* <LoginForm onSubmit={handleLogin}>
        <TextField
          type="text"
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          type="password"
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        
      </LoginForm> */}
    </LoginContainer>
  );
}

export default Login;
