import React, { useEffect, useState } from "react";
import config from "../config/config";
import Swal from "sweetalert2";
import { getCookie } from "../utils/cookieUtils";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Tab,
  Tabs,
  Box,
} from "@mui/material";
import PropTypes from "prop-types";
import { formatCurrency } from "../utils/formatterUtils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Positions = () => {
  const [data, setData] = useState(null);
  //realised, UNrealised, total
  const [total, setTotal] = useState(0);
  const [realised, setRealised] = useState(0);
  const [unrealised, setUnrealised] = useState(0);
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function handleRefresh() {
    try {
      getPositions();
    } catch (error) {
      console.error("handleRefresh error:", error);
    }
  }

  const getPositions = async () => {
    try {
      //read cookie from browser access_token
      const access_token = getCookie("access_token");
      const response = await fetch(
        config.API_BASE_URL +
          "dashboard/getPositions?access_token=" +
          access_token,
        {
          method: "GET",
        }
      );
      if (response.ok) {
        const jsonData = await response.json();
        console.log("jsonData", jsonData);
        if (jsonData.data.net.length > 0) {
          setData({ ...jsonData.data });

          setTotal(jsonData.data.net.reduce((acc, item) => acc + item.pnl, 0));
          setRealised(
            jsonData.data.net
              .filter((net) => net.quantity == 0)
              .reduce((acc, item) => acc + item?.pnl, 0)
          );
          setUnrealised(
            jsonData.data.net
              .filter((net) => net.quantity > 0)
              .reduce((acc, item) => acc + item?.pnl, 0)
          );
        }
      } else {
        const errorData = await response.json();
        console.log(errorData);
        Swal.fire("Error", "Error fetching Positions data.", "error");
      }
    } catch (error) {
      Swal.fire("Error", "Error fetching Positions data.", "error");
      console.error("Error fetching Positions data:", error);
    }
  };

  useEffect(() => {
    /* const interval = setInterval(() => {
      getPositions();
    }, 2000);

    return () => {
      clearInterval(interval);
    }; */

    getPositions();
  }, []);

  if (!data) {
    return <div>No Positions Available...</div>;
  }

  return (
    <div className="dashboard">
      <Typography variant="h5" style={{ marginBottom: "16px" }}>
        POSITIONS
      </Typography>
      <Grid container spacing={3} style={{ marginBottom: "8px" }}>
        <Grid item xs={12}>
          <Button variant="outlined" onClick={handleRefresh}>
            REFRESH
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" style={{ marginBottom: "8px" }}>
            PROFIT/LOSS : {formatCurrency(total)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" style={{ marginBottom: "8px" }}>
            REALISED: {formatCurrency(realised)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" style={{ marginBottom: "8px" }}>
            UNREALISED: {formatCurrency(unrealised)}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6" style={{ marginBottom: "8px" }}>
            TOTAL: {formatCurrency(total)}
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Tradingsymbol</TableCell>
                  <TableCell>Exchange</TableCell>
                  <TableCell>Instrument Token</TableCell>
                  <TableCell>Product</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Overnight Quantity</TableCell>
                  <TableCell>Multiplier</TableCell>
                  <TableCell>Average Price</TableCell>
                  <TableCell>Close Price</TableCell>
                  <TableCell>Last Price</TableCell>
                  <TableCell>Value</TableCell>
                  <TableCell>PnL</TableCell>
                  <TableCell>M2M</TableCell>
                  <TableCell>Realised</TableCell>
                  <TableCell>Unrealised</TableCell>
                  <TableCell>Buy Quantity</TableCell>
                  <TableCell>Buy Price</TableCell>
                  <TableCell>Buy Value</TableCell>
                  <TableCell>Buy M2M</TableCell>
                  <TableCell>Sell Value</TableCell>
                  <TableCell>Sell M2M</TableCell>
                  <TableCell>Sell Quantity</TableCell>
                  <TableCell>Sell Price</TableCell>
                  <TableCell>Sell Value</TableCell>
                  <TableCell>Day Buy Quantity</TableCell>
                  <TableCell>Day Buy Price</TableCell>
                  <TableCell>Day Buy Value</TableCell>
                  <TableCell>Day Sell Quantity</TableCell>
                  <TableCell>Day Sell Price</TableCell>
                  <TableCell>Day Sell Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.net.map((net, index) => (
                  <TableRow key={index}>
                    <TableCell>{net.tradingsymbol}</TableCell>
                    <TableCell>{net.exchange}</TableCell>
                    <TableCell>{net.instrument_token}</TableCell>
                    <TableCell>{net.product}</TableCell>
                    <TableCell>{net.quantity}</TableCell>
                    <TableCell>{net.overnight_quantity}</TableCell>
                    <TableCell>{net.multiplier}</TableCell>
                    <TableCell>{net.average_price}</TableCell>
                    <TableCell>{net.close_price}</TableCell>
                    <TableCell>{net.last_price}</TableCell>
                    <TableCell>{net.value}</TableCell>
                    <TableCell>{net.pnl}</TableCell>
                    <TableCell>{net.m2m}</TableCell>
                    <TableCell>{net.realised}</TableCell>
                    <TableCell>{net.unrealised}</TableCell>
                    <TableCell>{net.buy_quantity}</TableCell>
                    <TableCell>{net.buy_price}</TableCell>
                    <TableCell>{net.buy_value}</TableCell>
                    <TableCell>{net.buy_m2m}</TableCell>
                    <TableCell>{net.sell_value}</TableCell>
                    <TableCell>{net.sell_m2m}</TableCell>
                    <TableCell>{net.sell_quantity}</TableCell>
                    <TableCell>{net.sell_price}</TableCell>
                    <TableCell>{net.sell_value}</TableCell>
                    <TableCell>{net.day_buy_quantity}</TableCell>
                    <TableCell>{net.day_buy_price}</TableCell>
                    <TableCell>{net.day_buy_value}</TableCell>
                    <TableCell>{net.day_sell_quantity}</TableCell>
                    <TableCell>{net.day_sell_price}</TableCell>
                    <TableCell>{net.day_sell_value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid> */}

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="OPEN" {...a11yProps(0)} />
            <Tab label="CLOSED" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {Object.keys(data.net[0]).map((key) => (
                      <TableRow key={key}>
                        <TableCell>
                          <Typography>{key.toUpperCase()}</Typography>
                        </TableCell>
                        {data.net
                          .filter((net) => net.quantity > 0)
                          .map((net, index) => (
                            <TableCell key={index}>{net[key]}</TableCell>
                          ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6}>
              <TableContainer component={Paper}>
                <Table>
                  <TableBody>
                    {Object.keys(data.net[0]).map((key) => (
                      <TableRow key={key}>
                        <TableCell>
                          <Typography>{key.toUpperCase()}</Typography>
                        </TableCell>
                        {data.net
                          .filter((net) => net.quantity == 0)
                          .map((net, index) => (
                            <TableCell key={index}>{net[key]}</TableCell>
                          ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </div>
  );
};

export default Positions;
