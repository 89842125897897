import { config, Swal } from "./commonImports";
import { getCookie, expireCookie } from "./cookieUtils";

export async function handleLogout() {
  try {
    const access_token = getCookie("access_token");

    // Clear cookie
    expireCookie();

    // Call API to logout
    await fetch(
      config.API_BASE_URL + "login/logout?access_token=" + access_token,
      {
        method: "GET",
      }
    )
      .then((response) => {
        if (response.ok) {
          Swal.fire("Info", "Logged Out Successfully.", "info");
        }
      })
      .catch((error) => {
        console.error("Logout error:", error);
      });
  } catch (error) {
    console.error("Logout error:", error);
  } finally {
    //Add wait for 3 seconds before executing next line
    await new Promise((resolve) => setTimeout(resolve, 2000));
    window.location.href = "/";
  }
};
