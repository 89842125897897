import React, { useState, useEffect } from "react";
import { Button, Typography, Grid, Paper } from "@mui/material";
import "../style/index.css";

function DigitalClock() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  const tick = () => {
    setTime(new Date());
  };

  const formattedTime = time.toLocaleTimeString("en-IN", {
    timeZone: "Asia/Kolkata",
  });

  const getFontColor = () => {
    const hours = time.getHours();
    const minutes = time.getMinutes();

    if (
      (hours === 9 && minutes >= 0 && minutes <= 14) ||
      (hours === 9 && minutes === 15) ||
      (hours >= 10 && hours < 15) ||
      (hours === 15 && minutes <= 30)
    ) {
      return "green"; // Light green or green color for the specified time range
    } else {
      return "red"; // Red color for other times
    }
  };

  return (
    <Typography
      className="clock"
      style={{ margin: "5px", marginRight:"15px", color: getFontColor() }}
    >
      {formattedTime} IST
    </Typography>
  );
}

export default DigitalClock;
