import React, { useEffect, useState } from "react";
import config from "../config/config";
import Swal from "sweetalert2";
import { getCookie, expireCookie } from "../utils/cookieUtils";
import { Card, CardContent, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";

const Profile = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    // Simulating API call
    // Replace this with your actual API call code
    const getProfile = async () => {
      try {
        //read cookie from browser access_token
        const access_token = getCookie("access_token");
        const response = await fetch(
          config.API_BASE_URL +
            "dashboard/getprofile?access_token=" +
            access_token,
          {
            method: "GET",
          }
        );
        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData.data);
        } else {
          const errorData = await response.json();
          console.log(errorData);
          Swal.fire("Error", "Error fetching Profile data.", "error");
        }
      } catch (error) {
        console.error("Error fetching Profile data:", error);
      }
    };

    getProfile();
  }, []);

  if (!data) {
    return <div>Loading Profile...</div>;
  }

  const {
    user_id,
    email,
    user_name,
    user_shortname,
    broker,
    exchanges,
    products,
    order_types,
  } = data;

  return (
    <div
      className="dashboard"
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        margin: "16px",
        padding: "16px",
      }}
    >
      <Typography variant="h5" style={{ marginBottom: "16px" }}>
        Welcome, {user_shortname}
      </Typography>
      <div>
        <Typography className="typography-flex">
          <span className="title-left">USER ID</span>
          <span className="value-right">{user_id}</span>
        </Typography>
        <Typography className="typography-flex">
          <span className="title-left">EMAIL</span>
          <span className="value-right">{email}</span>
        </Typography>
        <Typography className="typography-flex">
          <span className="title-left">USER NAME</span>
          <span className="value-right">{user_name}</span>
        </Typography>
        <Typography className="typography-flex">
          <span className="title-left">BROKER</span>
          <span className="value-right">{broker}</span>
        </Typography>
        <Typography className="typography-flex">
          <span className="title-left">EXCHANGES</span>
          <span className="value-right">{exchanges.join(", ")}</span>
        </Typography>
        <Typography className="typography-flex">
          <span className="title-left">PRODUCTS</span>
          <span className="value-right">{products.join(", ")}</span>
        </Typography>
        <Typography className="typography-flex">
          <span className="title-left">ORDER TYPES</span>
          <span className="value-right">{order_types.join(", ")}</span>
        </Typography>
      </div>
    </div>
  );
};

export default Profile;
