// CopyToClipboardButton.js
import React from "react";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { copyToClipboard } from "../../utils/copyHelper"; // Adjust the path as necessary

function CopyToClipboardButton({ text }) {
  const handleCopy = () => {
    copyToClipboard(text);
  };

  return (
    <IconButton onClick={handleCopy} aria-label="copy">
      <ContentCopyIcon
        style={{
          fontSize: "1rem",
        }}
      />
    </IconButton>
  );
}

export default CopyToClipboardButton;
