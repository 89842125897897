import {
  React,
  useState,
  useEffect,
  config,
  Swal,
  handleLogout,
} from "../utils/commonImports";
import { styled } from "@mui/system";
import {
  Button,
  Typography,
  Grid,
  Paper,
  Divider,
  Hidden,
} from "@mui/material";
import LogViewer from "./logviewer";
import Header from "./header";
import Profile from "./profile";
import Margin from "./margins";
import Positions from "./positions";
import Instrument from "./instruments";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import SideBar from "./sideBar";
import { getCookie, expireCookie } from "../utils/cookieUtils";
import { formatCurrency } from "../utils/formatterUtils";

const TopBar = styled(Paper)(({ theme }) => ({
  backgroundColor: "#f0f0f0",
  padding: theme.spacing(1),
  borderRadius: theme.spacing(1),
  display: "flex",
  justifyContent: "flex-end",
}));


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Dashboard = () => {
  const [value, setValue] = React.useState(0);
  const [availableMargins, setAvailableMargins] = useState([]);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const updateAvailableMargin = (newValue) => {
    setAvailableMargins(newValue);
  };

  const toggleLoading = (isLoading) => {
    /* setLoading(isLoading); */
  };

  useEffect(() => {
    if (!document.cookie) {
      window.location.href = "/";
    }
  }, []);

  return (
    <Grid item xs={"auto"}>
      <Hidden smDown>
        <Header availableMargins={availableMargins} />
      </Hidden>
      <Hidden mdUp>
        <SideBar availableMargins={availableMargins} />
      </Hidden>
      <Grid container spacing={3}>
        <Grid
          container
          xs={6}
          style={{
            marginTop: "10px",
            padding: "10px",
          }}
        >
          <Hidden smDown>
            <Grid item xs={6}>
              <Profile />
            </Grid>
          </Hidden>
          <Grid item xs={6}>
            <Margin
              toggleLoading={toggleLoading}
              updateAvailableMargin={updateAvailableMargin}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="INSTRUMENTS" {...a11yProps(0)} />
                <Tab label="POSITIONS" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div>
                <Instrument availableMargins={availableMargins} />
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div>
                <Positions />
              </div>
            </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
