import React, { useEffect, useState, useCallback } from "react";
import config from "../config/config";
import Swal from "sweetalert2";
import { getCookie } from "../utils/cookieUtils";
import { CopyToClipboardButton } from "../utils/commonImports";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Tabs,
  Tab,
  Button,
  Divider,
  Hidden,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import OptionsCalculator from "./optionsCalculator";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Instrument = ({ availableMargins }) => {
  const [data, setData] = useState(null);
  const [value, setValue] = React.useState(0);

  const [selectedSymbol, setSelectedSymbol] = useState("");
  const [selectedPrice, setSelectedPrice] = useState(0);
  const [selectedInstrumentToken, setSelectedInstrumentToken] = useState(0);
  const [selectedLotSize, setSelectedLotSize] = useState(0);
  const [selectedButton, setSelectedButton] = useState(null);
  const [resetKey, setResetKey] = useState(0);

  const [lastNiftyPrice, setLastNiftyPrice] = useState({});
  const [lastPrices, setLastPrices] = useState({});
  const [performance, setPerformance] = useState({});
  const [webSocket, setWebSocket] = useState(null);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);

  const handleButtonClick = (tradingsymbol, instrument_token, lot_size) => {
    const latestPrice = lastPrices[instrument_token] || 0;

    // Handle button click logic here
    setSelectedButton({ tradingsymbol, latestPrice, lot_size });
    setSelectedSymbol(tradingsymbol);
    setSelectedInstrumentToken(instrument_token);
    setSelectedPrice(lastPrices[instrument_token] || 0);
    setSelectedLotSize(lot_size);
    setResetKey((prevKey) => prevKey + 1);
  };

  const handleResetButtonClick = () => {
    setSelectedButton(null);
    setSelectedSymbol("");
    setSelectedPrice(0);
    setSelectedLotSize(0);
    setResetKey((prevKey) => prevKey + 1);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getInstruments = async () => {
    try {
      //read cookie from browser access_token
      const access_token = getCookie("access_token");
      const response = await fetch(
        config.API_BASE_URL +
          "instruments/getInstruments?access_token=" +
          access_token,
        {
          method: "GET",
        }
      );
      if (response.ok) {
        const jsonData = await response.json();
        //console.log(jsonData);
        setData(jsonData.data);
      } else {
        const errorData = await response.json();
        console.log(errorData);
        Swal.fire("Error", "Error fetching Instrument data.", "error");
      }
    } catch (error) {
      console.error("Error fetching Instrument data:", error);
    }
  };

  const TableCellCallPrediction = () => {
    const description = (
      <React.Fragment>
        <div>Prediction strategies (Call Options):</div>
        <br />
        <div>
          Momentum Trading
          <br />
          Buy a call option when there is strong upward momentum
        </div>
        <br />
        <div>
          Mean Reversion
          <br />
          If the price has deviated significantly from the average traded price,
          consider it might revert back.
        </div>
        <br />
        <div>
          Breakout Trading
          <br />
          Buy a call option when the price breaks above the high of the day.
        </div>
        <br />
        <div>
          Open Interest Analysis
          <br />
          If the open interest is increasing, consider buying a call option.
        </div>
        <br />
        <div>
          Order Book Imbalance
          <br />
          If the order book is imbalanced towards the buy side, consider buying
          a call option.
        </div>
        <br />
        <div>
          Volume Analysis Call
          <br />
          If the volume is increasing, consider buying a call option.
        </div>
      </React.Fragment>
    );

    return (
      <TableCell>
        <Tooltip title={description} enterTouchDelay={0} leaveTouchDelay={5000}>
          <span>PRED</span>
        </Tooltip>
      </TableCell>
    );
  };

  const TableCellPutPrediction = () => {
    const description = (
      <React.Fragment>
        <div>Prediction strategies (Put Options):</div>
        <br />
        <div>
          Momentum Trading
          <br />
          Buy a put option when there is strong downward momentum
        </div>
        <br />
        <div>
          Mean Reversion
          <br />
          If the price has deviated significantly from the average traded price,
          consider it might revert back.
        </div>
        <br />
        <div>
          Breakout Trading
          <br />
          Buy a put option when the price breaks below the low of the day.
        </div>
        <br />
        <div>
          Open Interest Analysis
          <br />
          If the open interest is increasing, consider buying a put option.
        </div>
        <br />
        <div>
          Order Book Imbalance
          <br />
          If the order book is imbalanced towards the sell side, consider buying
          a put option.
        </div>
        <br />
        <div>
          Volume Analysis Put
          <br />
          If the volume is increasing, consider buying a put option.
        </div>
      </React.Fragment>
    );

    return (
      <TableCell>
        <Tooltip title={description} enterTouchDelay={0} leaveTouchDelay={5000}>
          <span>PRED</span>
        </Tooltip>
      </TableCell>
    );
  };

  const MAX_RECONNECT_ATTEMPTS = 10; // Maximum number of reconnect attempts
  const RECONNECT_MULTIPLIER = 2; // Multiplier for exponential backoff
  const RECONNECT_BASE_DELAY = 1000; // Base delay in milliseconds

  const initWebSocket = useCallback(() => {
    try {
      const ws = new WebSocket(config.WS_BASE_URL); // Replace with your WebSocket server URL

      ws.onopen = () => {
        console.log("WebSocket Client Connected");
        setReconnectAttempts(0);
      };

      ws.onmessage = (message) => {
        try {
          const data = JSON.parse(message.data);
          //console.log("Received data from server:", data);
          if (data.type === "ticks") {
            const tickDataArrayNifty = data.data.filter(
              (tickData) => tickData.instrument_token === 256265
            );

            setLastNiftyPrice((prevPrices) => {
              const newPrices = { ...prevPrices };
              tickDataArrayNifty.forEach((tickData) => {
                const token = tickData.instrument_token.toString(); // Ensure it's a string if required
                const lastPrice = tickData.last_price;
                newPrices[token] = tickData;
                //console.log("Nifty 50", newPrices[token]);
              });
              return newPrices;
            });

            const tickDataArray = data.data;

            setLastPrices((prevPrices) => {
              const newPrices = { ...prevPrices };
              tickDataArray.forEach((tickData) => {
                const token = tickData.instrument_token.toString(); // Ensure it's a string if required
                const lastPrice = tickData.last_price;
                const details = tickData;
                newPrices[token] = lastPrice;
              });
              return newPrices;
            });

            const performance = data.performance;
            setPerformance((prevPerformance) => {
              const newPerformance = { ...prevPerformance };
              performance.forEach((performanceData) => {
                const token = performanceData.instrument_token.toString(); // Ensure it's a string if required
                const performance = performanceData.performance;
                newPerformance[token] = performance;
              });
              return newPerformance;
            });
          }
        } catch (error) {
          console.error("Failed to parse message data:", error);
          Swal.fire(
            "Error",
            "WebSocket : Failed to parse message data",
            "error"
          );
        }
      };

      ws.onerror = (error) => {
        console.error("WebSocket Error:", error);
        Swal.fire("Error", "WebSocket Error", "error");
      };

      ws.onclose = () => {
        console.log("WebSocket Connection Closed");
        Swal.fire("Error", "WebSocket Connection Closed", "error");
        setReconnectAttempts((attempts) => {
          if (attempts < MAX_RECONNECT_ATTEMPTS) {
            const delay = Math.min(
              RECONNECT_BASE_DELAY * RECONNECT_MULTIPLIER ** attempts,
              30000
            ); // Exponential backoff with a cap
            setTimeout(() => {
              initWebSocket();
            }, delay);
            return attempts + 1;
          } else {
            console.error("Maximum reconnect attempts reached.");
            return attempts;
          }
        });
      };

      setWebSocket(ws);
    } catch (error) {
      console.error("An error occurred:", error);
      Swal.fire("Error", "Websocket : An error occurred", "error");
    }
  }, [reconnectAttempts]);

  useEffect(() => {
    getInstruments();
  }, []);

  useEffect(() => {
    if (data) {
      // Ensure data is available before initializing the WebSocket
      initWebSocket();
    }

    return () => {
      if (webSocket) {
        webSocket.close();
      }
    };
    // initWebSocket is stable because of useCallback, so it’s safe to include it in the dependencies array.
  }, [data, initWebSocket]);

  if (!data) {
    return <div>Loading Instrument...</div>;
  }

  return (
    <Box className="dashboard">
      <Grid
        container
        spacing={2}
        style={{
          padding: "15px",
        }}
      >
        <Grid item xs={12} sm={4.5}>
          <Tooltip
            title={
              <React.Fragment>
                <div>
                  Time : {lastNiftyPrice[256265]?.exchange_timestamp || "N/A"}
                </div>
                <br />
                <div>
                  Open : {lastNiftyPrice[256265]?.ohlc?.open || "N/A"}
                  <br />
                  High : {lastNiftyPrice[256265]?.ohlc?.high || "N/A"}
                  <br />
                  Low : {lastNiftyPrice[256265]?.ohlc?.low || "N/A"}
                  <br />
                  Close : {lastNiftyPrice[256265]?.ohlc?.close || "N/A"}
                </div>
              </React.Fragment>
            }
            enterTouchDelay={0}
            leaveTouchDelay={5000}
          >
            {/* change color to red if last_price is less than open */}
            <Typography
              variant="h5"
              style={{
                color:
                  lastNiftyPrice[256265]?.last_price <
                  lastNiftyPrice[256265]?.ohlc?.open
                    ? "red"
                    : "green",
              }}
            >
              NIFTY 50 {lastNiftyPrice[256265]?.last_price || "N/A"}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={12} sm={3.5}>
          <Typography variant="h5">EXPIRY {data.nextWeekExpiry}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            maxHeight: "50px",
          }}
        >
          <Button
            variant="contained"
            position="right"
            color="primary"
            onClick={() => handleResetButtonClick()}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <Divider style={{ marginBottom: "20px" }} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4.5}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="CALL" {...a11yProps(0)} />
                <Tab label="PUT" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>STRIKE</TableCell>
                      <Hidden smDown>
                        <TableCell>SYMBOL</TableCell>
                      </Hidden>
                      <TableCell>LTP</TableCell>
                      <TableCellCallPrediction />
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.ce.map((ceOption, index) => (
                      <TableRow key={index}>
                        <TableCell>{ceOption.strike}</TableCell>
                        <Hidden smDown>
                          <TableCell
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {ceOption.tradingsymbol}
                            <CopyToClipboardButton
                              text={ceOption.tradingsymbol}
                            />
                          </TableCell>
                        </Hidden>
                        <TableCell>
                          {lastPrices[ceOption.instrument_token] ||
                            ceOption.last_price}
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              ceOption &&
                              performance[ceOption.instrument_token] ? (
                                <React.Fragment>
                                  <div>
                                    Momentum Score:{" "}
                                    {performance[ceOption.instrument_token]
                                      ?.momentumScore || "N/A"}
                                  </div>
                                  <br />
                                  <div>
                                    Mean Reversion Score:{" "}
                                    {performance[ceOption.instrument_token]
                                      ?.meanReversionScore || "N/A"}
                                  </div>
                                  <br />
                                  <div>
                                    Breakout Trading Score:{" "}
                                    {performance[ceOption.instrument_token]
                                      ?.breakoutScore || "N/A"}
                                  </div>
                                  <br />
                                  <div>
                                    Open Interest Score:{" "}
                                    {performance[ceOption.instrument_token]
                                      ?.openInterestScore || "N/A"}
                                  </div>
                                  <br />
                                  <div>
                                    Order Book Imbalance Score:{" "}
                                    {performance[ceOption.instrument_token]
                                      ?.orderBookImbalanceScore || "N/A"}
                                  </div>
                                  <br />
                                  <div>
                                    Volume Analysis Score:{" "}
                                    {performance[ceOption.instrument_token]
                                      ?.volumeAnalysisScore || "N/A"}
                                  </div>
                                </React.Fragment>
                              ) : (
                                "N/A"
                              )
                            }
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                          >
                            <span>
                              {(performance[ceOption.instrument_token] &&
                                (
                                  performance[ceOption.instrument_token]
                                    .averageScore * 100
                                ).toFixed(2) + "%") ||
                                0}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() =>
                              handleButtonClick(
                                ceOption.tradingsymbol,
                                ceOption.instrument_token,
                                ceOption.lot_size
                              )
                            }
                            style={{
                              backgroundColor:
                                selectedButton &&
                                selectedButton.tradingsymbol ===
                                  ceOption.tradingsymbol
                                  ? "#dbefff" // Set the desired style for the selected button
                                  : "white", // Set the default style for other buttons
                              borderColor:
                                selectedButton &&
                                selectedButton.tradingsymbol ===
                                  ceOption.tradingsymbol
                                  ? "#2196F3" // Set the desired style for the selected button
                                  : "white", // Set the default style for other buttons
                            }}
                          >
                            SELECT
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>STRIKE</TableCell>
                      <Hidden smDown>
                        <TableCell>SYMBOL</TableCell>
                      </Hidden>
                      <TableCell>LTP</TableCell>
                      <TableCellPutPrediction />
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.pe.map((peOption, index) => (
                      <TableRow key={index}>
                        <TableCell>{peOption.strike}</TableCell>
                        <Hidden smDown>
                          <TableCell
                            style={{
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            {peOption.tradingsymbol}
                            <CopyToClipboardButton
                              text={peOption.tradingsymbol}
                            />
                          </TableCell>
                        </Hidden>
                        <TableCell>
                          {lastPrices[peOption.instrument_token] ||
                            peOption.last_price}
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              peOption &&
                              performance[peOption.instrument_token] ? (
                                <React.Fragment>
                                  <div>
                                    Momentum Score:{" "}
                                    {performance[peOption.instrument_token]
                                      ?.momentumScore || "N/A"}
                                  </div>
                                  <br />
                                  <div>
                                    Mean Reversion Score:{" "}
                                    {performance[peOption.instrument_token]
                                      ?.meanReversionScore || "N/A"}
                                  </div>
                                  <br />
                                  <div>
                                    Breakout Trading Score:{" "}
                                    {performance[peOption.instrument_token]
                                      ?.breakoutScore || "N/A"}
                                  </div>
                                  <br />
                                  <div>
                                    Open Interest Score:{" "}
                                    {performance[peOption.instrument_token]
                                      ?.openInterestScore || "N/A"}
                                  </div>
                                  <br />
                                  <div>
                                    Order Book Imbalance Score:{" "}
                                    {performance[peOption.instrument_token]
                                      ?.orderBookImbalanceScore || "N/A"}
                                  </div>
                                  <br />
                                  <div>
                                    Volume Analysis Score:{" "}
                                    {performance[peOption.instrument_token]
                                      ?.volumeAnalysisScore || "N/A"}
                                  </div>
                                </React.Fragment>
                              ) : (
                                "N/A"
                              )
                            }
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                          >
                            <span>
                              {(performance[peOption.instrument_token] &&
                                (
                                  performance[peOption.instrument_token]
                                    .averageScore * 100
                                ).toFixed(2) + "%") ||
                                0}
                            </span>
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() =>
                              handleButtonClick(
                                peOption.tradingsymbol,
                                peOption.instrument_token,
                                peOption.lot_size
                              )
                            }
                            style={{
                              backgroundColor:
                                selectedButton &&
                                selectedButton.tradingsymbol ===
                                  peOption.tradingsymbol
                                  ? "#dbefff" // Set the desired style for the selected button
                                  : "white", // Set the default style for other buttons
                              borderColor:
                                selectedButton &&
                                selectedButton.tradingsymbol ===
                                  peOption.tradingsymbol
                                  ? "#2196F3" // Set the desired style for the selected button
                                  : "white", // Set the default style for other buttons
                            }}
                          >
                            SELECT
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </Box>
        </Grid>
        <Grid item xs={12} sm={7.5}> <div className="scrollableContent">
            <OptionsCalculator
              tradingsymbol={selectedSymbol}
              lastPrice={lastPrices[selectedInstrumentToken] || selectedPrice}
              lotSize={selectedLotSize}
              key={resetKey}
              availableMargins={availableMargins}
            />
            </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Instrument;
